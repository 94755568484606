<template>
  <div>
    <div class="search">
      <el-input  placeholder="请输入关键词" class="input" size="large" v-model="searchData.question"
      @keyup.enter.native="search"></el-input>
      <el-button size="large" @click="search">搜索</el-button>
    </div>
    <div class="content">
      <el-table :data="tableData" width="250" style="width: 100%" 
      class="list"
      :header-cell-style="{color:'#333'}">
        <el-table-column prop="question" label="问题示例" width="300" />
        <el-table-column prop="answer" label="答案"  />
      </el-table>
    </div>
    <el-pagination
        :currentPage="searchData.index"
        :page-sizes="[20, 40, 60, 80,100,120]"
        :page-size="searchData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
  </div>
</template>

<script>
import {request} from '@/network/request'
  export default {
    data() {
      return {
        searchData: {
          question: "",
          index: 1,
          pageSize: 20,
        },
        total: 0,
        tableData : []
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.searchData.pageSize = val;
        this.getAllAnswer(this.searchData.index,this.searchData.pageSize)
      },
      // 监听 页码值 改变事件
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.searchData.index = val;
        this.getAllAnswer(this.searchData.index,this.searchData.pageSize)
      },
      getAllAnswer(index,pageSize) {
        request({
          url: '/csff/teaKnowledge/selectAnswer',
          method: "post",
          data: {
            "index": index,
            "pageSize": pageSize
          }
        }).then(res => {
          if(res.data.code == 200){
            console.log(res.data.content)
            this.tableData = res.data.content.data;
            this.total = res.data.content.total
          }else if(res.data.code == 400){
            console.log("查询失败")
          }
        }).catch(err => {
          console.log(err)
        })
      },
      search(searchContent){
        request({
          url: '/csff/teaKnowledge/selectAnswer',
          method: "post",
          data: this.searchData
        }).then(res => {
          if(res.data.code == 200){
            console.log(res.data.content)
            this.tableData = res.data.content.data;
            this.total = res.data.content.total
          }else if(res.data.code == 400){
            console.log("查询失败")
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    mounted() {
      this.getAllAnswer(this.searchData.index,this.searchData.pageSize)
    }
  }
</script>

<style scoped>
.search{
  width: 1400px;
  margin: 0 auto;
  margin-top: 50px;
}
.input{
  width: 50%;
  margin-right: 30px;
}
.el-input{
  --el-input-focus-border: #2c7613;
}
.el-button{
--el-button-hover-border-color: #86c436;
--el-button-hover-bg-color:#86c436;
--el-button-hover-text-color:rgb(253, 253, 252)5;
}
.content{
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
}
.list{
  margin-bottom: 50px;
}
.el-table{
  font-size: 16px;
  color: black;
}
</style>