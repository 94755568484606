import axios from 'axios'
import config from '@/config'

const baseURL = process.env.NODE_ENV === 'development' ? config.baseURL.dev : config.baseURL.prod;
axios.defaults.withCredentials = true;
//请求响应函数
export function request(config) {
    const http = axios.create({
        baseURL : baseURL,
        timeout : 30000,
        
    })

  return http(config);
}
